
import { Options, Vue } from 'vue-class-component';
import CreditCardPaymentMethod from '@/modules/order/details/components/payment/credit-card-payment.component.vue';
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';
import { ModelError, orderModule } from '@/modules/order/orderModule.vuex-module';
import { Time } from '@/common/models/Time.model';
import { isThisSecond } from 'date-fns';
import { timestamp } from '@vueuse/core';

@Options({
	name: 'TimeConfirmView',
	components: { CreditCardPaymentMethod },
})
export default class TimeConfirmView extends Vue {
	loading = false;
	error: string | null = null;

	to = '';
	from = '';
	priceDifference = 0;

	get activity(): OrderActivity | null {
		return orderModule.openActivity;
	}
	get fromTime(): Time | null {
		return this.activity?.selectedTimes[0] ?? null;
	}

	get priceDirection(): string {
		return this.priceDifference < 0 ? 'save you' : 'cost an additional';
	}
	mounted() {
		if (this.activity?.pendingTime) this.to = this.activity.pendingTime.AppointmentDate + (this.activity.activity.AppointmentTypeKey == 1 ? ' @ ' + this.activity.pendingTime.DisplayName : '');
		this.from = this.activity?.selectedTimes.map((time) => time.AppointmentDate + (time.AppointmentTypeKey == 1 ? ' @ ' + time.DisplayName : '')).join(', ') ?? 'None';
		const fromCost = this.activity?.pendingTime?.SubTotal ?? 0;
		const toCost = this.fromTime?.Cost ?? 0;
		this.priceDifference = fromCost - toCost;
	}
	next() {
		this.loading = true;
		orderModule.openActivity?.selectPendingTime();
		orderModule
			.saveToOrder()
			.then((res) => {
				if (this.$route.params.orderEntityTimeKey) orderModule.selectByOrderEntityTimeKey(this.$route.params.orderEntityTimeKey as string);
				if (res) {
					const me = res as [ModelError];
					if (me.length) {
						console.log(me);
						this.error = me.flat().join(', ');
						console.log(this.error);
					} else if ((orderModule._activeOrder?.balance ?? 0) < 0) {
						this.$router.push('refund');
					} else if ((orderModule._activeOrder?.balance ?? 0) > 0) {
						this.$router.push('payment');
					} else {
						this.$router.push({ name: 'OrderTime', query: { success: 'true' } });
					}
				} else {
					this.error = 'Error saving order!';
				}
				this.loading = false;
			})
			.catch((e) => {
				console.log(e);
				this.error = 'Unable to save, please try again later';
				this.loading = false;
			});
	}
}
